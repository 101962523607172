import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { graphql, Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import lodash from 'lodash';
import { IZZZEngine } from '../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { Col, Row } from 'react-bootstrap';
import { ZZZEngine } from '../../modules/zzz/common/components/zzz-engine';

interface IZZZEngineNodes {
  nodes: IZZZEngine[];
}

interface IZZZEngineEntry {
  allCharacters: IZZZEngineNodes;
}

interface IProps {
  data: IZZZEngineEntry;
}

const ZZZEngines: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search W-Engines...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'B',
            tooltip: 'B',
            image: (
              <StaticImage
                src="../../images/zzz/icons/item_rarity_b.png"
                width={24}
                alt="B"
              />
            )
          },
          {
            value: 'A',
            tooltip: 'A',
            image: (
              <StaticImage
                src="../../images/zzz/icons/item_rarity_a.png"
                width={24}
                alt="A"
              />
            )
          },
          {
            value: 'S',
            tooltip: 'S',
            image: (
              <StaticImage
                src="../../images/zzz/icons/item_rarity_s.png"
                width={24}
                alt="S"
              />
            )
          }
        ]
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Anomaly',
            tooltip: 'Anomaly',
            image: (
              <StaticImage
                src="../../images/zzz/icons/style_anomaly.png"
                width={24}
                alt="Anomaly"
              />
            )
          },
          {
            value: 'Attack',
            tooltip: 'Attack',
            image: (
              <StaticImage
                src="../../images/zzz/icons/style_attack.png"
                width={24}
                alt="Attack"
              />
            )
          },
          {
            value: 'Defence',
            tooltip: 'Defence',
            image: (
              <StaticImage
                src="../../images/zzz/icons/style_defence.png"
                width={24}
                alt="Defence"
              />
            )
          },
          {
            value: 'Stun',
            tooltip: 'Stun',
            image: (
              <StaticImage
                src="../../images/zzz/icons/style_stun.png"
                width={24}
                alt="Stun"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/zzz/icons/style_support.png"
                width={24}
                alt="Support"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'engineId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.toLowerCase() === activeFilters.type.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke '}
      game="zzz"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>W-Engines</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/zzz/categories/category_engines.png"
            alt="W-Engines"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero W-Engines</h1>
          <h2>List of all W-Engines (Weapons) in Zenless Zone Zero.</h2>
          <p>
            Last updated: <strong>30/03/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="W-Engines"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} W-Engine
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar zzz">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Row xs={1} xxl={2} className="relic-set-container">
        {visibleCharacters
          .filter((char) => char.rarity === 'S')
          .map((emp, index) => {
            return (
              <Col key={index}>
                <ZZZEngine name={emp.name} mode="card" />
              </Col>
            );
          })}
        {visibleCharacters
          .filter((char) => char.rarity === 'A')
          .map((emp, index) => {
            return (
              <Col key={index}>
                <ZZZEngine name={emp.name} mode="card" />
              </Col>
            );
          })}
        {visibleCharacters
          .filter((char) => char.rarity === 'B')
          .map((emp, index) => {
            return (
              <Col key={index}>
                <ZZZEngine name={emp.name} mode="card" />
              </Col>
            );
          })}
      </Row>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/zzz/no_results.webp"
            alt="No results"
          />
          <p>No W-Engines found. Try changing your filters.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default ZZZEngines;

export const Head: React.FC = () => (
  <Seo
    title="W-Engines | Zenless Zone Zero | Prydwen Institute"
    description="List of all W-Engines (Weapons) in Zenless Zone Zero."
    game="zzz"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulZzzEngine(sort: { fields: name, order: ASC }) {
      nodes {
        engineId
        name
        rarity
        talentName
        element
        type
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        description {
          raw
        }
        stats {
          stat
          base_atk
          max_atk
          base_special
          max_special
        }
      }
    }
  }
`;
